<template>
  <v-container>
    <div>
      <CreateCustomerUseProductDialog
        ref="create"
        @customer-use-product-created="getDataFromAPI"
      />
      <EditCustomerUseProductDialog
        ref="edit"
        @customer-use-product-edited="getDataFromAPI"
      />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-card-title>
        <v-icon medium>
          mdi-wan
        </v-icon>
        ผลิตภัณฑ์ที่ลูกค้าใช้งานในบริการ {{ customerUseServiceCategoryName }}
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerUseProductDialog(customerUseServiceId)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Product
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="loading == false && customerUseProducts.length !== 0">
        <v-data-table
          :headers="headers"
          :items="customerUseProducts"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
        >
          <template v-slot:[`item.installationFee`]="{ item }">
            <span>
              {{
                item.installationFee !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.installationFee)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.monthlyFee`]="{ item }">
            <span>
              {{
                item.monthlyFee !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.monthlyFee)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <span>
              {{ formatDate(item.startDate) }}
            </span>
          </template>
          <template v-slot:[`item.expireDate`]="{ item }">
            <v-icon v-if="calExpireDateDiff(item.expireDate)" small color="red">
              mdi-alert
            </v-icon>
            <span>
              {{ formatDate(item.expireDate) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="openEditCustomerUseProductDialog(item)">
              <v-icon small color="secondary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="confirmDeleteCustomerUseProduct(item)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateCustomerUseProductDialog from "./CreateCustomerUseProductDialog";
import EditCustomerUseProductDialog from "./EditCustomerUseProductDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";

moment.locale("th");
export default {
  name: "CustomerUseProductCard",
  components: {
    CreateCustomerUseProductDialog,
    EditCustomerUseProductDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    customerUseServiceId: {
      type: Number,
    },
    customerUseServiceCategoryName: {
      type: String,
    },
  },
  data() {
    return {
      customerUseProducts: {},
      loading: false,
      headers: [
        {
          text: "ผลิตภัณฑ์",
          value: "productCategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "Code",
          value: "code",
          sortable: false,
          align: "center",
        },
        {
          text: "ค่าติดตั้ง",
          value: "installationFee",
          sortable: false,
          align: "center",
        },
        {
          text: "ค่าบริการรายเดือน",
          value: "monthlyFee",
          sortable: false,
          align: "center",
        },
        {
          text: "วันเริ่มต้นสัญญา",
          value: "startDate",
          sortable: false,
          align: "center",
        },
        {
          text: "วันสิ้นสุดสัญญา",
          value: "expireDate",
          sortable: false,
          align: "center",
        },
        {
          text: "หมายเหตุ",
          value: "remark",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer-use-service/" + this.customerUseServiceId + "/customer-use-product")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerUseProducts = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
    calExpireDateDiff(date) {
      if (date) {
        const now = moment(new Date());
        const duration = moment.duration(now.diff(date));
        const dayDiff = duration.asDays();
        if (dayDiff >= -30) {
          return true;
        } else {
          return false;
        }
      }
      return null;
    },
    openCreateCustomerUseProductDialog: async function(customerUseServiceId) {
      await this.$refs.create.open(customerUseServiceId);
    },
    openEditCustomerUseProductDialog: async function(customerUseProduct) {
      await this.$refs.edit.open(customerUseProduct);
    },
    confirmDeleteCustomerUseProduct: async function(customerUseProduct) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบผลิตภัณฑ์ที่ลูกค้าใช้งาน",
          "คุณแน่ใจที่จะลบผลิตภัณฑ์ที่ลูกค้าใช้งานนี้?"
        )
      ) {
        this.deleteCustomerUseProduct(customerUseProduct);
      }
    },
    deleteCustomerUseProduct: async function(customerUseProduct) {
      this.loading = true;
      axios
        .delete("/customer-use-product/" + customerUseProduct.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerUseProductResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerUseProductResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
