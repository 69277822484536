var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-map ")]),_vm._v(" แผนที่ ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.redirect('https://www.google.com/maps/search/?api=1&query='+_vm.customer.latitude+','+_vm.customer.longitude)}}},[_c('v-icon',{attrs:{"medium":"","color":"secondary"}},[_vm._v("mdi-open-in-new")])],1)],1),(_vm.loading == false)?_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(this.customer.latitude !== null && this.customer.longitude !== null)?_c('gmap-map',{staticStyle:{"width":"100%","height":"310px"},attrs:{"center":{
              lat: Number(this.customer.latitude),
              lng: Number(this.customer.longitude),
            },"zoom":18}},[_c('gmap-marker',{attrs:{"position":{
                lat: Number(this.customer.latitude),
                lng: Number(this.customer.longitude),
              }}})],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }