<template>
  <v-container>
    <div>
      <CreateCustomerInventoryDialog
        ref="create"
        @customer-inventory-created="getDataFromAPI"
      />
      <EditCustomerInventoryDialog
        ref="edit"
        @customer-inventory-edited="getDataFromAPI"
      />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
          mdi-dolly
        </v-icon>
        อุปกรณ์ที่ลูกค้าใช้งาน
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerInventoryDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Inventory
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium>
          mdi-dolly
        </v-icon>
        อุปกรณ์ที่ลูกค้าใช้งาน
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerInventoryDialog(id)"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Inventory
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false && customerInventories.length !== 0">
        <v-data-table
          :headers="headers"
          :items="customerInventories"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [5] }"
        >
          <template v-slot:[`item.costPrice`]="{ item }">
            <span>
              {{
                item.costPrice !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.costPrice)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.installationDate`]="{ item }">
            <span>
              {{ formatDate(item.installationDate) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="openEditCustomerInventoryDialog(item)">
              <v-icon small color="secondary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="confirmDeleteCustomerInventory(item)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateCustomerInventoryDialog from "./CreateCustomerInventoryDialog";
import EditCustomerInventoryDialog from "./EditCustomerInventoryDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";

moment.locale("th");
export default {
  name: "CustomerInventoryCard",
  components: {
    CreateCustomerInventoryDialog,
    EditCustomerInventoryDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      customerUseInventories: {},
      loading: false,
      headers: [
        {
          text: "ประเภท",
          value: "inventoryType.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ยี่ห้อ",
          value: "inventoryBrand.name",
          sortable: false,
          align: "center",
        },
        {
          text: "รุ่น",
          value: "model",
          sortable: false,
          align: "center",
        },
        {
          text: "S/N",
          value: "sn",
          sortable: false,
          align: "center",
        },
        {
          text: "ราคาทุน",
          value: "costPrice",
          sortable: false,
          align: "center",
        },
        {
          text: "วันติดตั้ง",
          value: "installationDate",
          sortable: false,
          align: "center",
        },
        {
          text: "รายละเอียด",
          value: "description",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-inventory")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerInventories = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
    openCreateCustomerInventoryDialog: async function(id) {
      await this.$refs.create.open(id);
    },
    openEditCustomerInventoryDialog: async function(customerInventory) {
      await this.$refs.edit.open(customerInventory);
    },
    confirmDeleteCustomerInventory: async function(customerInventory) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบอุปกรณ์ที่ลูกค้าใช้งาน",
          "คุณแน่ใจที่จะลบอุปกรณ์ที่ลูกค้าใช้งานนี้?"
        )
      ) {
        this.deleteCustomerInventory(customerInventory);
      }
    },
    deleteCustomerInventory: async function(customerInventory) {
      this.loading = true;
      axios
        .delete("/customer-inventory/" + customerInventory.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerInventoryResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerInventoryResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
