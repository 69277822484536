<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          สร้างอุปกรณ์ที่ลูกค้าใช้งาน.
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-select
                :items="inventoryTypes"
                v-model="customerInventory.inventoryType_id"
                name="inventoryType"
                item-text="name"
                item-value="id"
                label="ประเภทอุปกรณ์"
                color="secondary"
                required
                :rules="[(v) => !!v || 'Inventory type is required']"
                item-color="grey"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="inventoryBrands"
                v-model="customerInventory.inventoryBrand_id"
                name="inventoryBrand"
                item-text="name"
                item-value="id"
                label="ยี่ห้ออุปกรณ์"
                color="secondary"
                required
                :rules="[(v) => !!v || 'Inventory brand is required']"
                item-color="grey"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerInventory.model"
                color="secondary"
                name="model"
                hint="รุ่น"
                label="รุ่น"
                clearable
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerInventory.sn"
                color="secondary"
                name="sn"
                hint="S/N"
                label="S/N"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
              <v-text-field
                v-model="customerInventory.costPrice"
                color="secondary"
                name="costPrice"
                hint="ราคาทุน"
                label="ราคาทุน"
                type="number"
                :rules="costPriceRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12">
              <v-menu
                v-model="installationDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="secondary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerInventory.installationDate"
                    label="วันติดตั้ง"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customerInventory.installationDate"
                  @input="installationDateMenu = false"
                  color="blue"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="customerInventory.description"
                color="secondary"
                name="description"
                hint="รายละเอียด"
                label="รายละเอียด เช่น MAC Address หรือ สิ่งที่ต้องการจะนำมาใช้เวลาค้นหาอุปกรณ์"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
          >save</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateCustomerInventoryDialog",
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      inventoryTypes: null,
      inventoryBrands: null,
      customer: {
        name: null,
      },
      customerInventory: {
        costPrice: 0
      },
      installationDateMenu: false,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      costPriceRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Cost price has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Cost price is required",
      ],
    };
  },
  created() {
    this.getInventoryTypes();
    this.getInventoryBrands();
  },
  methods: {
    open: function (id) {
      this.dialog = true;
      this.id = id;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getInventoryTypes() {
      axios.get("/inventory-type").then((response) => {
        this.inventoryTypes = response.data;
      });
    },
    getInventoryBrands() {
      axios.get("/inventory-brand").then((response) => {
        this.inventoryBrands = response.data;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        axios
          .post(
            "/customer/" + this.id + "/customer-inventory",
            this.customerInventory
          )
          .then((response) => {
            this.createCustomerInventoryResponse = response.data;
            if (this.createCustomerInventoryResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("customer-inventory-created");
            }
          })
          .catch(function () {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
