import { render, staticRenderFns } from "./CreateCustomerContactDialog.vue?vue&type=template&id=56df758c&scoped=true&"
import script from "./CreateCustomerContactDialog.vue?vue&type=script&lang=js&"
export * from "./CreateCustomerContactDialog.vue?vue&type=script&lang=js&"
import style0 from "./CreateCustomerContactDialog.vue?vue&type=style&index=0&id=56df758c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56df758c",
  null
  
)

export default component.exports