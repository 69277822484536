<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          แก้ไขผลิตภัณฑ์ที่ลูกค้าใช้งาน.
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-select
                :items="productCategories"
                v-model="customerUseProduct.productCategory_id"
                name="productCategory"
                item-text="name"
                item-value="id"
                label="ประเภทผลิตภัณฑ์"
                color="primary"
                required
                :rules="[(v) => !!v || 'Product category is required']"
                item-color="grey"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerUseProduct.code"
                color="primary"
                name="code"
                hint="Code"
                label="Code"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerUseProduct.installationFee"
                color="primary"
                name="installationFee"
                hint="ค่าติดตั้ง"
                label="ค่าติดตั้ง"
                type="number"
                :rules="installationFeeRules"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerUseProduct.monthlyFee"
                color="primary"
                name="monthlyFee"
                hint="ค่าบริการรายเดือน"
                label="ค่าบริการรายเดือน"
                type="number"
                :rules="monthlyFeeRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerUseProduct.startDate"
                    label="วันเริ่มต้นสัญญา"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customerUseProduct.startDate"
                  @input="startDateMenu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-menu
                v-model="expireDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerUseProduct.expireDate"
                    label="วันสิ้นสุดสัญญา"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customerUseProduct.expireDate"
                  color="primary"
                  @input="expireDateMenu = false"
                >
                </v-date-picker> </v-menu
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="customerUseProduct.remark"
                color="primary"
                name="remark"
                hint="หมายเหตุ"
                label="หมายเหตุ"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
          >save</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "EditCustomerUseProductDialog",
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      productCategories: null,
      customer: {
        name: null,
      },
      customerUseProduct: {
        id: null,
        productCategory_id: null,
        code: null,
        installationFee: null,
        monthlyFee: null,
        startDate: null,
        expireDate: null,
        remark: null,
      },
      startDateMenu: false,
      expireDateMenu: false,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      customerUseProductCodeRules: [(value) => !!value || "Code is required"],
      installationFeeRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Installation fee has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Installation fee is required",
      ],
      monthlyFeeRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Monthly fee has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Monthly fee is required",
      ],
    };
  },
  created() {
    this.getProductCategories();
  },
  methods: {
    open: function(customerUseProduct) {
      this.dialog = true;
      this.customerUseProduct = JSON.parse(JSON.stringify(customerUseProduct));
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getProductCategories() {
      axios.get("/product-category").then((response) => {
        this.productCategories = response.data;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        delete this.customerUseProduct.customer_id;
        delete this.customerUseProduct.productCategory;
        axios
          .put(
            "/customer-use-product/" + this.customerUseProduct.id,
            this.customerUseProduct
          )
          .then((response) => {
            this.editCustomerUseProductResponse = response.data;
            if (this.editCustomerUseProductResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("customer-use-product-edited");
            }
          })
          .catch(function() {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
