<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
          mdi-map
        </v-icon>
        แผนที่
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="redirect('https://www.google.com/maps/search/?api=1&query='+customer.latitude+','+customer.longitude)          "
        >
          <v-icon medium color="secondary">mdi-open-in-new</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium>
          mdi-map
        </v-icon>
        แผนที่
        <v-spacer />
        <v-btn
          icon
          @click="redirect('https://www.google.com/maps/search/?api=1&query='+customer.latitude+','+customer.longitude)          "
        >
          <v-icon medium color="secondary">mdi-open-in-new</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false">
        <v-row align="center">
          <v-col cols="12">
            <gmap-map v-if="this.customer.latitude !== null && this.customer.longitude !== null"
              :center="{
                lat: Number(this.customer.latitude),
                lng: Number(this.customer.longitude),
              }"
              :zoom="18"
              style="width:100%;  height: 310px;"
            >
              <gmap-marker
                :position="{
                  lat: Number(this.customer.latitude),
                  lng: Number(this.customer.longitude),
                }"
              ></gmap-marker>
            </gmap-map>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CustomerLocationCard",
  data() {
    return {
      center: {},
    };
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
    customer: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  mounted() {
    // this.geolocate();
    // this.center.lat = Number(this.customer.latitude);
    // this.center.lng = Number(this.customer.longitude);
  },

  methods: {
    // geolocate: function() {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.center = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude,
    //     };
    //   });
    // },
    redirect: function(link, target = "_blank") {
      console.log(link)
      window.open(link, target);
    },
  },
};
</script>
