<template>
  <v-container>
    <div>
      <CreateCustomerUseServiceDialog
        ref="create"
        @customer-use-service-created="getDataFromAPI"
      />
      <EditCustomerUseServiceDialog
        ref="edit"
        @customer-use-service-edited="getDataFromAPI"
      />
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
           mdi-security
        </v-icon>
        บริการ All@Secure & SI
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="openCreateCustomerUseServiceDialog(id)"
          >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Service
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text v-if="loading == false && customerUseServices.length !== 0">
        <v-data-table
          :headers="headers"
          :items="customerUseServices"
          class="elevation-1"
          show-expand
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row wrap dense>
                <v-col 
                  dense
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  grow
                  pa-1
                >
                  <customer-use-product-card
                    :customerUseServiceId="item.id"
                    :customerUseServiceCategoryName="item.serviceCategory.name"
                    :customerUseProducts="item.customerUseProducts"
                  />
                </v-col>
              </v-row>
            </td>
            <!-- <td :colspan="headers.length">
              More info about {{ item.serviceCategory.name }}
            </td> -->
          </template>
          <template v-slot:[`item.installationFee`]="{ item }">
            <span>
              {{
                item.installationFee !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.installationFee)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.monthlyFee`]="{ item }">
            <span>
              {{
                item.monthlyFee !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.monthlyFee)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.specialBill`]="{ item }">
            <v-icon v-if="item.specialBill" small color="green">
              mdi-check-bold
            </v-icon>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <span>
              {{ formatDate(item.startDate) }}
            </span>
          </template>
          <template v-slot:[`item.expireDate`]="{ item }">
            <v-icon v-if="calExpireDateDiff(item.expireDate)" small color="red">
              mdi-alert
            </v-icon>
            <span>
              {{ formatDate(item.expireDate) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon @click="openEditCustomerUseServiceDialog(item)">
              <v-icon small color="secondary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="confirmDeleteCustomerUseService(item)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateCustomerUseServiceDialog from "./CreateCustomerUseServiceDialog";
import EditCustomerUseServiceDialog from "./EditCustomerUseServiceDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";
import CustomerUseProductCard from "../CustomerUseProduct/CustomerUseProductCard";

moment.locale("th");
export default {
  name: "CustomerUseServiceCard",
  components: {
    CreateCustomerUseServiceDialog,
    EditCustomerUseServiceDialog,
    ConfirmDeleteDialog,
    CustomerUseProductCard,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      customerUseServices: {},
      loading: false,
      headers: [
        {
          text: "บริการ",
          value: "serviceCategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "หมายเลขบริการหลัก",
          value: "code",
          sortable: false,
          align: "center",
        },
        {
          text: "ค่าติดตั้ง",
          value: "installationFee",
          sortable: false,
          align: "center",
        },
        {
          text: "ค่าบริการรายเดือน",
          value: "monthlyFee",
          sortable: false,
          align: "center",
        },
        {
          text: "ใบแจ้งหนี้พิเศษ",
          value: "specialBill",
          sortable: false,
          align: "center",
        },
        {
          text: "วันเริ่มต้นสัญญา",
          value: "startDate",
          sortable: false,
          align: "center",
        },
        {
          text: "วันสิ้นสุดสัญญา",
          value: "expireDate",
          sortable: false,
          align: "center",
        },
        {
          text: "หมายเหตุ",
          value: "remark",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-use-service")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerUseServices = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
    calExpireDateDiff(date) {
      if (date) {
        const now = moment(new Date());
        const duration = moment.duration(now.diff(date));
        const dayDiff = duration.asDays();
        if (dayDiff >= -30) {
          return true;
        } else {
          return false;
        }
      }
      return null;
    },
    openCreateCustomerUseServiceDialog: async function(id) {
      await this.$refs.create.open(id);
    },
    openEditCustomerUseServiceDialog: async function(customerUseService) {
      await this.$refs.edit.open(customerUseService);
    },
    confirmDeleteCustomerUseService: async function(customerUseService) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบบริการที่ลูกค้าใช้งาน",
          "คุณแน่ใจที่จะลบบริการที่ลูกค้าใช้งานนี้?"
        )
      ) {
        this.deleteCustomerUseService(customerUseService);
      }
    },
    deleteCustomerUseService: async function(customerUseService) {
      this.loading = true;
      axios
        .delete("/customer-use-service/" + customerUseService.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerUseServiceResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerUseServiceResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
