<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          สร้างอัลบั้ม.
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model.trim="customerAlbum.name"
            :rules="rules"
            color="secondary"
            counter="25"
            hint="ชื่ออัลบั้ม"
            label="ชื่ออัลบั้ม"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
          >save</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateCustomerAlbumDialog",
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      customer: {
        name: null,
      },
      customerAlbum: {
        name: null,
      },
      images: null,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      rules: [(value) => !!value || "Album name is required"],
    };
  },
  methods: {
    open: function(id) {
      this.dialog = true;
      this.id = id;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        axios
          .post("/customer/" + this.id + "/customer-album", this.customerAlbum)
          .then((response) => {
            this.createAlbumResponse = response.data;
            if (this.createAlbumResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("album-created");
            }
          })
          .catch(function() {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
