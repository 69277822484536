<template>
  <v-container>
    <div>
      <ConfirmDeleteDialog ref="confirm" />
    </div>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium>
          mdi-handshake
        </v-icon>
        รายการเข้าพบลูกค้า
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="'/customer/' + id + '/create-customer-meeting'" 
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Meeting
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium>
          mdi-handshake
        </v-icon>
        รายการเข้าพบลูกค้า
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="'/customer/' + id + '/create-customer-meeting'" 
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          Add Meeting
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false && customerMeetings.length !== 0">
        <v-data-table
          :headers="headers"
          :items="customerMeetings"
          class="elevation-1"
          :footer-props="{ itemsPerPageOptions: [5] }"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span>
              {{ formatDate(item.date) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon :to="'/customer-meeting/' + item.id + '/show'">
              <v-icon small color="secondary">mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon :to="`/customer-meeting/${item.id}/edit`">
              <v-icon small color="secondary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="confirmDeleteCustomerMeeting(item)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
// import CreateCustomerInventoryDialog from "./CreateCustomerMeetingDialog";
// import EditCustomerInventoryDialog from "./EditCustomerMeetingDialog";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";

moment.locale("th");
export default {
  name: "CustomerMeetingCard",
  components: {
    // CreateCustomerMeetingDialog,
    // EditCustomerMeetingDialog,
    ConfirmDeleteDialog,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      customerMeetings: {},
      loading: false,
      headers: [
        {
          text: "วันที่เข้าพบ",
          value: "date",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer/" + this.id + "/customer-meeting")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerMeetings = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (date !== null) {
        // return `${moment(date).format("LL")}`;
        return new Date(date).toLocaleDateString("th-TH", {
          dateStyle: "long",
        });
      }
      return null;
    },
    confirmDeleteCustomerMeeting: async function(customerMeeting) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบรายการเข้าพบลูกค้า",
          "คุณแน่ใจที่จะลบรายการเข้าพบลูกค้านี้?"
        )
      ) {
        this.deleteCustomerMeeting(customerMeeting);
      }
    },
    deleteCustomerMeeting: async function(customerMeeting) {
      this.loading = true;
      axios
        .delete("/customer-meeting/" + customerMeeting.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerMeetingResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerMeetingResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
